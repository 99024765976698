<template>
  <v-app-bar class="navbar" flat color="transparent">
    <app-bar-nav-icon />
    <v-toolbar-title class="breadcrumbs d-flex align-center">
      <v-icon class="text-light-blue">{{ icons.arrowLeft }}</v-icon>
      <router-link
        class="text-decoration--none"
        :to="{
          name: 'settings.organisation',
          params: { tab: 'templates' },
        }"
        >Templates
      </router-link>
    </v-toolbar-title>
  </v-app-bar>
</template>
<script>
/**
 * ==================================================================================
 * Perk Appbar
 * ==================================================================================
 **/

import AppBarNavIcon from '@/layouts/shared/AppBarNavIcon.vue'
import { mdiChevronLeft } from '@mdi/js'

export default {
  components: {
    AppBarNavIcon,
  },
  data() {
    return {
      icons: {
        arrowLeft: mdiChevronLeft,
      },
    }
  },
}
</script>
