<template>
  <div class="template-details py-8">
    <TemplateAppbar />

    <v-row class="mx-4">
      <v-col cols="12" class="ma-0 pa-0">
        <div class="ma-4">
          <div class="mb-6">
            <v-row>
              <v-col cols="6" md="4">
                <ViewingTitle :title="displayName" />
              </v-col>
              <v-col cols="6" md="2">
                <DeleteOrRestore
                  v-if="hasDeletePermissions"
                  :model="template"
                  name="Template"
                  redirect="/settings/organisation/templates"
                />
              </v-col>
            </v-row>

            <TemplateForm
              class="template-details__form"
              :template="template"
              :mergeFields="mergeFields"
            />
          </div>
        </div>
      </v-col>
    </v-row>

    <Loading :value="loading" />
  </div>
</template>
<script>
/**
 * ==================================================================================
 * View Template Request
 * ==================================================================================
 **/

import { mapState, mapActions, mapMutations } from 'vuex'
import TemplateAppbar from '@/views/Home/Settings/Organisation/Templates/components/TemplateAppbar'
import TemplateForm from '@/views/Home/Settings/Organisation/Templates/components/TemplateForm'
import SnackbarMixin from '@/utils/mixins/Snackbar'
import ErrorHandlerMixin from '@/utils/mixins/ErrorHandler'
import Loading from '@/components/common/Loading'
import { validatePermissions } from '@/utils/auth'
import PERMISSION from '@/utils/enums/Permission'
import ViewingTitle from '@/components/common/ViewingTitle.vue'
import DeleteOrRestore from '@/components/fields/DeleteOrRestore/index.vue'

export default {
  name: 'TemplateDetails',

  components: {
    DeleteOrRestore,
    ViewingTitle,
    TemplateAppbar,
    TemplateForm,
    Loading,
  },

  mixins: [SnackbarMixin, ErrorHandlerMixin],

  data() {
    return {
      loading: false,
    }
  },

  computed: {
    ...mapState({
      template: (state) => state.template.templateDetails,
      mergeFields: (state) => state.template.mergeFields,
      permissions: (state) => state.auth.permissions,
    }),

    displayName() {
      return this.template ? this.template.name : null
    },

    hasDeletePermissions() {
      return validatePermissions([PERMISSION.TAGS_DELETE], this.permissions)
    },
  },

  methods: {
    ...mapActions({
      getTemplateDetails: 'template/getTemplateDetails',
      getMergeFields: 'template/getMergeFields',
    }),

    ...mapMutations({
      clearTemplateDetails: 'template/clearTemplateDetails',
    }),

    async getTemplate() {
      this.loading = true
      await this.getTemplateDetails(this.$route.params.id)
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },

    async getMergeField() {
      this.loading = true
      await this.getMergeFields()
        .catch((err) => {
          this.showSnackbar(this.getErrorMessage(err), false)
        })
        .finally(() => {
          this.loading = false
        })
    },
  },

  created() {
    this.getTemplate()
    this.getMergeField()
  },

  destroyed() {
    this.clearTemplateDetails()
  },

  watch: {
    $route() {
      this.getTemplate()
    },
  },
}
</script>

<style lang="scss" scoped>
.template-details {
  &__title {
    padding-right: 10px;
  }

  &__divider {
    max-width: 500px;
  }

  @media (max-width: 768px) {
    &__divider {
      max-width: 100%;
    }
  }
}
</style>
